import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import TelegramIcon from "../components/telegramicon"
import AppleIcon from "../components/appleicon"
import SpotifyIcon from "../components/spotifyicon"
import GoogleIcon from "../components/googleicon"
import YoutubeIcon from "../components/youtubeicon"
import TwitterIcon from "../components/twitter"
import CabanaIcon from "../components/cabanaicon"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >

      <div style={{float:'left'}}>
        <Link
          to="/"
          style={{
            color: `var(--darkpurple)`,
            textDecoration: `none`,
          }}
        >
          <CabanaIcon/>
        </Link>
      </div>
      <div style={{float:'left', margin: '5px 10px'}}>
        <Link
          to="/"
          style={{
            color: `var(--darkpurple)`,
            textDecoration: `none`,
          }}
        >
          Podcast
        </Link>
      </div>
      <div style={{float:'left', marginTop: '5px'}}>
        <Link
          to="/advisory"
          style={{
            color: `var(--darkpurple)`,
            textDecoration: `none`,
          }}
        >
          Advisory
        </Link>
      </div>
      <div style={{float:'right'}}>
        <a href="https://twitter.com/BitcoinCabana" target="_blank" rel="noreferrer"><TwitterIcon/></a>
      </div>
      <div style={{float:'right'}}>
        <a href="https://t.me/bitcoincabana" target="_blank" rel="noreferrer"><TelegramIcon/></a>
      </div>
      <div style={{float:'right'}}>
        <a href="https://www.youtube.com/@bitcoincabana" target="_blank" rel="noreferrer"><YoutubeIcon/></a>
      </div>
      <div style={{clear:'both'}}>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
